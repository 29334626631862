:root{
	--white_clr: #fff;
	--black_clr: #000;
	--text_clr: #111;
	--primary_clr: #3b22e0;
	--secondary_clr: #6555cd;
	--site_clr_1: #3b22e0;
	--site_clr_2: #ea5455;
	--site_clr_3: #1773ea;
	--site_clr_4: #28c76f;
	--gray_bg: #eaf1f3;
	--bdr_clr: #c5c5c5;
	--light_bdr_clr: #d8d6de;
	--primary_font_family: 'Arial', sans-serif;
}
/*color: var(--white_clr);*/
::-moz-selection{background:var(--primary_clr); color: var(--white_clr); text-shadow:none;}
::selection{background:var(--primary_clr); color: var(--white_clr); text-shadow:none;}
/*Loader*/
#preloader{position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0);overflow:hidden;z-index: 9999; display:flex;display:-webkit-flex; align-items: center; justify-content: center;}
#loader{display:block;position:relative;width:80px;height:80px;border-radius:50%;border:3px solid transparent;border-top-color:#fafafa;-webkit-animation:spin 2s linear infinite;animation:spin 2s linear infinite;}
#loader:before{content:"";position:absolute;top:5px;left:5px;right:5px;bottom:5px;border-radius:50%;border:3px solid transparent;border-top-color:#fafafa;-webkit-animation:spin 3s linear infinite;animation:spin 3s linear infinite;}
#loader:after{content:"";position:absolute;top:15px;left:15px;right:15px;bottom:15px;border-radius:50%;border:3px solid transparent;border-top-color:#fafafa;-webkit-animation:spin 1.5s linear infinite;animation:spin 1.5s linear infinite;}
@-webkit-keyframes spin{0%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);}
 100%{-webkit-transform:rotate(360deg);-ms-transform:rotate(360deg);transform:rotate(360deg);}
}
@keyframes spin{0%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);}
 100%{-webkit-transform:rotate(360deg);-ms-transform:rotate(360deg);transform:rotate(360deg);}
}
/* ===== GLOBAL ================================================== */
body{margin:0; padding:0; background: var(--gray_bg); font-family: var(--primary_font_family); font-size:14px; color: var(--text_clr); line-height:normal; font-weight:500; letter-spacing: 0.14px}
.trans{-moz-transition:all 0.4s ease 0s;-webkit-transition:all 0.4s ease 0s;transition:all 0.4s ease 0s;}
.hide{display: none;}
.v-center{align-items: center;}
.j-center{justify-content: center;}
.ml-auto{
	margin-left: auto !important;
}
.mr-auto{
	margin-right: auto !important;
}
.overlay-box{
	display:none; position:fixed; top:0; left:0; width:100%; bottom:0; background:var(--black_clr); z-index:9; opacity:0.4;
}
.overlay-box.active,
.mobi-menu-active .overlay-box{
	display:block;
}
.bg1{background: var(--site_clr_1);}
.bg2{background: var(--site_clr_2);}
.bg3{background: var(--site_clr_3);}
.bg4{background: var(--site_clr_4);}
.spinner-border {
    width: 28px; height: 28px;
}
.spinner-border + *{
    display: none;
}
img{
	max-width:100%; height:auto;
}
.fallback-spinner {
    height: 100%; align-items: center; justify-content: center; text-align: center; background: var(--gray_bg); position: fixed; left: 0; right: 0; top: 0; bottom: 0; z-index: 9999;
}
.fallback-spinner.app-loader {
    height: 100vh; flex-direction: column;
}
.siteicon-spinner{
	width: 110px; height: 110px; background: var(--white_clr); overflow: hidden; -webkit-border-radius: 50%;-moz-border-radius: 50%;border-radius: 50%; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto;
}
.siteicon-spinner img{
	max-width: 60px;
}
.btn-primary:not(.btn-light):not(.btn-secondary):focus,
.custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):focus:after,
.btn-primary:not(.btn-light):not(.btn-secondary):active,
.custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):active:after{
    background: var(--primary_clr);
    border-color: var(--primary_clr);
    color: var(--white_clr);
}
/* =============GLOBAL CSS END================ */
.sidebar-fixed {
    position: fixed; min-height: 100vh;
}
.page-body-wrapper{
	margin-left: 260px; min-height: 100vh;
}
.sidebar-icon-only .page-body-wrapper{
	margin-left: 60px;
}
@media screen and (min-width:992px){
.sidebar-hover .navbar{
	left: 60px;
}
.sidebar-hover .page-body-wrapper{
	margin-left: 60px;
}
.sidebar-hover .page-body-wrapper{
	width: calc(100% - 60px);
}
}
.active.la-angle-double-right:before{
    content: "\f100";
}
.sidebar{
	border-right: 1px solid var(--bdr_clr);
}
.sidebar .sidebar-brand-wrapper{
	border-right: 1px solid var(--bdr_clr);
}
.scrollbar-container{
	padding: 62px 0; height: 100vh; overflow-x: hidden; overflow-y: auto; position: relative;
}
.sidebar .scrollbar-container .nav{
	margin: 0; padding: 0;
}
.live_chat_block{
	padding: 5px 0; position: fixed; left: 0; bottom: 0; z-index: 999; width: 260px; background: var(--white_clr); border-right: 1px solid var(--bdr_clr);
}
.sidebar-icon-only .live_chat_block{
	width: 60px;
}
/*.live_chat_link{
	padding: 8px 5px; font-size: 15px; line-height: normal; color: var(--black_clr); font-weight: 400; -webkit-border-radius: 4px;-moz-border-radius: 4px;border-radius: 4px;
}
.live_chat_link .chat_icon{
	min-width: 20px; margin: 8px 0;
}
.live_chat_link span{
	margin-top: 1px;
}*/
.navbar .navbar-menu-wrapper{
	border-bottom: 1px solid var(--bdr_clr); box-shadow: none; position: relative; z-index: 2;
}
.toggler-btnbox{
    position: absolute; right: 0; top: 0; bottom: 0; margin: auto; display: flex; align-items: center;
}
.toggler-btnbox .navbar-toggler,
.toggler-head-btnbox .navbar-toggler{
	font-size: 18px !important; color: var(--primary_clr) !important;
}
.sidebar-icon-only .toggler-btnbox{
	display: none;
}
.sidebar-icon-only .toggler-head-btnbox.hide{
/*	display: block;*/
}
.sidebar-icon-only .toggler-btnbox .la-angle-double-left:before{
	content: "\f101";
}
.sidebar-icon-only .sidebar .nav .nav-item .nav-link{
    justify-content: center;
}
.sidebar .nav.sub-menu .nav-item:first-of-type{
    margin-top: 7px !important;
}
.sidebar-icon-only .sidebar .nav.sub-menu .nav-item:first-of-type{
    margin-top: 0 !important;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link.search{
	padding: 0;
}
.sidebar .nav .nav-item .nav-link .menu-title{
	font-size: 15px; font-weight: 500; letter-spacing: 0.14px;
}
.sidebar .nav.sub-menu .nav-item .nav-link{
	font-size: 15px; font-weight: 500; letter-spacing: 0.14px;
}
form.search {
    position: relative;
}
.navbar .navbar-menu-wrapper .search input{
	padding: 4px 50px 4px 15px; background: var(--white_clr); border: 1px solid var(--light_bdr_clr); width: 100%; height: 36px;
}
.navbar .navbar-menu-wrapper .search input:hover,
.navbar .navbar-menu-wrapper .search input:focus{
	background: var(--gray_bg);
}
.search-btn {
    display: inline-flex; align-items: center; justify-content: center; width: 32px; height: 32px; border: 0; background: none; font-size: 20px; line-height: normal; color: var(--black_clr); position: absolute; right: 2px; top: 2px; -webkit-border-radius: 50%;-moz-border-radius: 50%;border-radius: 50%;
}
.search-btn:hover{
	background: var(--white_clr); color: var(--primary_clr);
}
.help-nav-btnbox .help-button{
	display: flex; justify-content: center; align-items: center; background: var(--white_clr) !important; border: 1px solid var(--light_bdr_clr) !important; padding: 10px 12px; gap: 5px; border-radius: 4px; height: 40px; font-weight: 500;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown.help-nav-btnbox .dropdown-toggle:after{
	font-size: 18px; color: var(--text_clr); opacity: 0.8;
}
.sidebar .nav > .nav-item:first-child{
    margin-top: 0.5rem;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown{
	padding: 0.5rem 0; box-shadow: 0 5px 25px rgba(34,41,47,.1); border: 1px solid #e6e7e7;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
    padding: 8px 12px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
    font-size: 20px;
}
.drop-icon-item {
    font-size: 14px; line-height: 18px; color: var(--text_clr);
}
.drop-icon-item i {
    color: var(--primary_clr); margin-right: 6px;
}
.help-button + .dropdown-menu.navbar-dropdown{
	top: -7px !important;
}
.help-nav-btnbox .help-button i{
	font-size: 20px; color: var(--primary_clr);
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown .dropdown-item i{
	font-size: 20px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link .navbar-profile .navbar-profile-name{
	font-weight: 500; margin: 0 5px 0 0; letter-spacing: 0.14px; text-align: right;
}
.navbar-profile .img-xs{
	min-width: 35px; border: 1px solid rgba(0,0,0,0.1);
}
.navbar-profile-name .name-text {
    display: block; max-width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
}
.user-status{
	display: block; font-size: 12px; line-height: 12px; font-weight: 400; margin-top: 5px;
}
.help-nav-btnbox .help-button {
    padding: 0; min-width: 32px; max-width: 32px; height: 35px;
}
.help-icon{
	max-width: 20px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown.help-nav-btnbox .dropdown-toggle:after{
	display: none;
}
.noti-iconbox img{
	max-width: 21px; opacity: 0.6;
}
.noti-iconbox:hover img{
	opacity: 1;
}

/*Notification Dropdown*/
.notifications-list{
    width: 418px;
}
.notifi-head {
    padding: 1rem 1.28rem; border-bottom: 1px solid #ebe9f1; margin-top: -0.5rem; font-weight: 500; font-size: 15px; line-height: 18px;
}
.view_more {
    text-decoration: underline; font-weight: 500; color: var(--primary_clr);
}
.noti-items{
    padding: 0.9rem 1.28rem; display: flex; flex-grow: 1; border-bottom: 1px solid #ebe9f1; position: relative;
}
.notification-content {
    display: block; font-size: 14px; line-height: 18px; font-weight: 500; color: #555; text-decoration: none;
}
.notification-content:hover{
	text-decoration: none; color: var(--text_clr);
}
.noti-items:hover{
	background: var(--gray_bg);
}
.remove_icon{
	font-size: 14px; line-height: normal; color: var(--black_clr); cursor: pointer; position: absolute; right: 8px;
}
/*Footer*/
.footer{
	padding: 10px 1rem; background: var(--white_clr); font-family: var(--primary_font_family); font-size:14px; color: var(--text_clr);
}
.footer a{
	color: var(--primary_clr);
}

/*Card Style*/
.card-list{
	margin-left: -7px; margin-right: -7px;
}
.card-list .items{
	padding-left: 7px; padding-right: 7px;
}
.card{
	border: 1px solid var(--bdr_clr); -webkit-border-radius: 6px;-moz-border-radius: 6px;border-radius: 6px;
}
.card_items + .card_items{
	margin-top: 20px;
}
.card .card-body {
    padding: 20px;
}
.card-head-title {
    margin: 0 0 15px;
}
.card .card-title{
	font-size: 15px;
}
.card-growth {
    padding: 1rem; flex-wrap: wrap; height: 120px; overflow: hidden; position: relative; -webkit-border-radius: 6px;-moz-border-radius: 6px;border-radius: 6px;
}
.bg-box{
	background: #fafafa; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-size: cover !important;
}
.card-growth-inn{
	z-index: 2; flex-wrap: wrap;
}
.card-head {
    width: 100%; margin-bottom: 1rem;
}
.card-growth .iconbox{
	display: inline-flex; align-items: center; justify-content: center; min-width: 50px; height: 50px; -webkit-border-radius: 6px;-moz-border-radius: 6px;border-radius: 6px; margin-right: 15px;
	font-size: 34px; line-height: 34px; color: var(--white_clr);
}
.card-growth .iconbox img{
	max-width: 34px;
}
.price-text {
    display: inline-block; font-size: 22px; line-height: 28px; font-weight: 600; color: var(--black_clr);
}
.price-text.added_star {
    font-size: 30px;
}
.card-text-link {
    display: inline-flex; font-size: 14px; line-height: normal; color: var(--black_clr); font-weight: 500;
}
.card-text-link:hover{
	color: var(--primary_clr);
}
.bg-iconbox{
	position: absolute; right: 10px; bottom: 0; max-width: 70px; font-size: 70px; color: currentColor; opacity: 0.15; display: none !important;
}
.clr_1 ~ .bg-iconbox{
	color: var(--site_clr_1);
}
.clr_2 ~ .bg-iconbox{
	color: var(--site_clr_2);
}
.clr_3 ~ .bg-iconbox{
	color: var(--site_clr_3);
}
.clr_4 ~ .bg-iconbox{
	color: var(--site_clr_4);
}
.bg-iconbox img{
	display: block; width: 100%; height: auto;
}
.revenue-tl{
	margin-bottom: 15px;
}
.revenue-tl .icon{
    display: inline-flex; align-items: center; justify-content: center; width: 50px; height: 50px; margin-right: 20px; font-size: 32px;
}
.revenue-tl .icon img{
	max-width: 34px;
}
.total-textbox p {
    font-size: 16px; font-weight: 500;
}
.label {
    display: inline-block; font-size: 14px; line-height: normal; color: var(--text_clr);
}
.invoice-date {
    margin-left: 20px;
}
.dropdown-item:hover,
.dropdown-item:focus{
    color: var(--black_clr); background-color: #e0f9fc;
}
.selectbox-style .btn{
	display: flex; justify-content: center; align-items: center; background: none !important; border: 1px solid var(--light_bdr_clr) !important; padding: 5px 10px; gap: 5px; border-radius: 4px; height: 36px;
	font-size: 14px; line-height: normal; color: var(--text_clr); font-weight: 500;
}
.selectbox-style .btn.dropdown-toggle:after{
	font-size: 18px; color: var(--black_clr); opacity: 1; content: "\f107"; font-family: 'FontAwesome'; font-weight: 400; border: 0 !important; margin: 0;
}
.selectbox-style.dropdown .dropdown-menu{
	padding: 0.5rem 0; box-shadow: 0 5px 25px rgba(34,41,47,.1); border: 1px solid #e6e7e7; top: 100% !important;
	-webkit-transform:translate(0,0) !important;-moz-transform:translate(0,0) !important;transform:translate(0,0) !important;
}
.selectbox-style.dropdown .dropdown-menu .dropdown-item {
    padding: 8px 12px;
}
.card .selectbox-style .btn{
	height: 26px;
}
.form-switch-style{
	position: relative; cursor: pointer;
}
.form-switch-style .form-check-input{
	display: block; width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 3; margin: 0; opacity: 0; cursor: pointer;
}
.switch-check-box{
	padding: 0 7px; text-align: center; width: 54px; height: 25px; position: relative; align-items: center; font-size: 14px; line-height: 24px; -webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;
	background: #e2e2e2; color: var(--black_clr);
}
.switch-check-box span {
    width: 50%;
}
.switch-icon-left{
	opacity: 0;
}
.switch-check-box:after{
	display: block; content: ""; background: var(--white_clr); width: 15px; height: 15px; -webkit-border-radius: 50%;-moz-border-radius: 50%;border-radius: 50%;
	position: absolute; top: 0; bottom: 0; left: 6px; margin: auto; -moz-transition:all 0.4s ease 0s;-webkit-transition:all 0.4s ease 0s;transition:all 0.4s ease 0s;
}
.form-switch-style input:checked ~ .switch-check-box{
/*	color: var(--white_clr); background: #1773ea;*/
}
.form-switch-style input:checked ~ .switch-check-box:after{
	left: 33px;
}
.form-switch-style input:checked ~ .switch-check-box .switch-icon-left{
	opacity: 1;
}
.form-switch-style input:checked ~ .switch-check-box .switch-icon-right{
	opacity: 0;
}
.identix-growth-cards{
	position: relative;
}
.small-text{
	display: block; font-size: 12px; line-height: 16px; color: var(--black_clr); font-weight: 500; margin: 0 0 -5px;
}
.card-inflow{
	position: relative;
}
.card-inflow .iconbox{
	align-items: center; justify-content: center; min-width: 80px; max-width: 80px; height: 80px; background: var(--primary_clr); -webkit-border-radius: 50%;-moz-border-radius: 50%;border-radius: 50%; margin-right: 20px;
}
.card-inflow .iconbox img{
	display: block; margin: auto; max-width: 40px;
}
.inflow-list .items{
	margin-top: 20px;
}
.clr_1.card-inflow .iconbox{
	background: rgba(0,207,232,.5);
}
.clr_2.card-inflow .iconbox{
	background: rgba(234,84,85,.5);
}
.clr_3.card-inflow .iconbox{
	background: rgba(115,103,239,.5);
}
.clr_4.card-inflow .iconbox{
	background: rgba(40,199,111,.5);
}
.clr_5.card-inflow .iconbox{
	background: rgba(23,115,234,.5);
}
.clr_6.card-inflow .iconbox{
	background: rgba(255,159,67,.5);
}
.clr_1 .price-text{
	color: var(--site_clr_1);
}
.clr_2 .price-text{
	color: var(--site_clr_2);
}
.clr_3 .price-text{
	color: rgba(115,103,239,1);
}
.clr_4 .price-text{
	color: var(--site_clr_4);
}
.clr_5 .price-text{
	color: var(--site_clr_3);
}
.clr_6 .price-text{
	color: #ff9f43;
}
.square_iconbox{
	min-width: 50px; max-width: 50px; min-height: 50px; align-items: center; justify-content: center; font-size: 24px;
}
.active-card .card-text-link{
	font-size: 14px;
}
.active-card .price-text{
	display: block; margin-top: 5px;
}
.graph_block{
	max-width: 380px;
}
.graph-tl{
	display: inline-block; padding: 12px 0; border-bottom: 3px solid #555;
}

/*Login/Register pages*/
.account-login-main {
    background: var(--white_clr); min-height: 100vh; position: relative;
}
.page-body-wrapper.full-page-wrapper{
	margin: 0; background: var(--white_clr);
}
.account-login-main .bg-img{
	background: url('../images/login-bg.png') no-repeat right top; background-size: auto 100% !important;
	position: absolute; left: 0; right: 0; top: 0; bottom: 0;
}
.gst-login-img img {
    max-width: 480px;
}
.gst-card {
    word-wrap: break-word; background-clip: initial; /*background-color: var(--white_clr); border: initial; border-radius: 20px; box-shadow: 0 0 20px rgba(23,115,234,.3);*/ display: flex; flex-direction: column; min-width: 0; position: relative; width: 100%; transition: all .3s ease-in-out,background 0s,color 0s,border-color 0s;
}
.gst-login-logo img{
	display: block; margin: 0 auto; max-width: 252px;
}
.gst-welcome-text h2 {
    font-size: 24px;
    font-weight: 600;
}
.gst-welcome-text p {
    font-size: 14px; font-weight: 400; margin: 0;
}
.munim-switch-btn-wrap {
    margin: 20px 0;
}
.munim-switch-btn {
    border: 1px solid var(--primary_clr); border-radius: 100px; display: inline-flex; gap: 5px; padding: 5px;
}
.munim-switch-btn .nav-pills .nav-link{
    padding: 6px 20px; display: inline-flex; align-items: center; justify-content: center; text-align: center; font-size: 16px; line-height: 24px; color: var(--black_clr); font-weight: 600; -webkit-border-radius: 40px;-moz-border-radius: 40px;border-radius: 40px;
}
.munim-switch-btn .nav-pills .nav-link.active{
	background: var(--primary_clr); color: var(--white_clr);
}
.form-group label{
	display: inline-block; font-size: 14px; line-height: normal; color: rgb(33, 37, 41); font-weight: 500; position: relative; margin-bottom: 5px;
}
.form-control{
    font-size: 14px;
}
.input-group-text {
    position: relative; align-items: center; background-color: var(--white_clr); border: 1px solid #ced4da; border-radius: 0.25rem; color: #212529; display: flex; font-size: 1rem; font-weight: 400; line-height: 1.5; padding: 0.375rem 0.75rem; text-align: center; white-space: nowrap;
}
.input-group-text.left{
	border-bottom-right-radius: 0; border-top-right-radius: 0;
}
.input-group-text.right{
	border-bottom-left-radius: 0; border-top-left-radius: 0;
}
.required-star:after {
    bottom: 0; color: red; content: "*"; font-size: 16px; margin-left: 3px; position: absolute; right: -8px;
}
.form-control.form-control-sm{
	height: 38px; background: var(--white_clr); border: 1px solid var(--light_bdr_clr); font-size: 14px; color: var(--black_clr); -webkit-border-radius: 0.357rem;-moz-border-radius: 0.357rem;border-radius: 0.357rem; outline: 0 !important;
}
.small-select-style .form-control.form-control-sm,
.small-input-style .form-control.form-control-sm{
	padding: 0 5px; height: 28px; min-width: 120px; width: 100%; position: relative;
}
.small-select-style{
	position: relative; background: var(--white_clr); border: 1px solid var(--light_bdr_clr); -webkit-border-radius:0.357rem;-moz-border-radius:0.357rem;border-radius:0.357rem;
}
.small-select-style .form-control.form-control-sm{
	background: none;border: 0; padding-right: 20px; width: 100%; -webkit-appearance: none; -moz-appearance: none; appearance: none; position: relative; z-index: 1; text-overflow: ellipsis;
}
.small-select-style:after{
	display: inline-flex; align-items: center; justify-content: center; position:absolute; top:0; right:0; content: "\f107"; font-family: 'FontAwesome'; font-weight: 400; font-size: 18px; color: var(--black_clr); z-index:0; width: 24px; height: 100%; border: 0; -moz-transition:all 0.4s ease 0s;-webkit-transition:all 0.4s ease 0s;transition:all 0.4s ease 0s;
}
.payamt-selectbox{
	height: 38px; background: var(--white_clr); border: 1px solid var(--light_bdr_clr); font-size: 14px; color: var(--black_clr); -webkit-border-radius: 0.357rem;-moz-border-radius: 0.357rem;border-radius: 0.357rem; outline: 0 !important;
}
.payamt-selectbox .form-control.form-control-sm{
	-webkit-border-radius: 0;-moz-border-radius: 0;border-radius: 0; border: 0; height: 100%;
}
.payamt-selectbox select.form-control.form-control-sm {
    background: #eee; padding: 0 4px; border: 0; font-size: 13px; min-width: 120px; max-width: 120px;
}
.instruction-text {
    font-weight: 500; opacity: 0.7;
}
.resend-btnbox .btn {
    height: 38px;
}
.input-group-text + div .form-control{
	-webkit-border-top-left-radius: 0;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-topleft: 0;
	-moz-border-radius-bottomleft: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.right-icon .form-control{
	-webkit-border-top-right-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-topright: 0;
	-moz-border-radius-bottomright: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
input[type=number] {
	-webkit-appearance: textfield; -moz-appearance: textfield; appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}
.form-control.form-control-sm:focus{
	border-color: var(--primary_clr);
}
.form-control.is-invalid{
	border-color: red !important;
}
.form-check .form-check-label {
    color: rgb(33, 37, 41);
}
.form-check{
	padding-top: 4px;
}
.form-check-input {
    width: 16px; height: 16px; margin-top: 2px;
}
.form-btnbox .btn{
	padding: 0.375rem 0.75rem; display: inline-flex; align-items: center; justify-content: center; text-align: center; font-size: 14px; line-height: 21px; color: var(--primary_clr); font-weight: 500; -webkit-border-radius: 5.012px;-moz-border-radius: 5.012px;border-radius: 5.012px;
}
.form-btnbox .btn:hover,
.form-btnbox .btn:focus{
	background-color: var(--primary_clr);
    border-color: var(--primary_clr);
    color: var(--white_clr);
}
.form-btnbox .btn-primary{
    background-color: var(--primary_clr);
    border-color: var(--primary_clr);
    color: var(--white_clr);
}
.form-btnbox .btn-primary:hover{
    background-color: var(--white_clr);
    border-color: var(--primary_clr);
    color: var(--primary_clr) !important;
}
.gst-register-btn {
    padding: 1rem 0 0;
    border-top: 1px solid #ebe9f1;
}
.change_login_btn {
    display: inline-block; font-size: 14px; line-height: 20px; color: var(--primary_clr); font-weight: 500; padding: 0; border: 0; background: none;
}
.change_login_btn:hover,
.change_login_btn:focus{
	text-decoration: underline;
}
.error-hover-tooltip {
    cursor: pointer; height: 18px; position: absolute; right: 15px; top: 10px; width: 18px; z-index: 9;
    background: url('../images/error.svg') no-repeat center; background-size: 100% auto;
}
.error-message {
    color: #df4857;
}
.custom-tooltip {
    background-color: #df4857; border-radius: 5px!important; font-size: 12px; opacity: 0; padding: 5px 13px; position: absolute; right: 0; bottom: 100%; margin-bottom: 10px;
}
.custom-tooltip:after {
    border-color: #df4857 transparent transparent; border-style: solid; border-width: 0.4rem 0.4rem 0; bottom: -4px; content: ""; position: absolute; right: 40px; z-index: 99999999;
}
.custom-tooltip .custom-tooltip-label p {
    color: #fdf0f1; margin: 0; padding: 0; font-size: 12px; line-height: 18px;
}
.error-hover-tooltip:hover + .custom-tooltip{
    opacity: 1;
}
.view_pass{
	cursor: pointer;
}

.gst_active .acc_user_show{
	display: none;
}
.gst_active .gst_user_show{
	display: block;
}
.dwn-flt .selectbox-style .btn{
	border: 0 !important; padding: 0; outline: none !important; box-shadow: none !important; text-align: left;
}
.dwn-flt .selectbox-style .btn.dropdown-toggle:after{
	display: none;
}
.dwn-flt .selectbox-style.dropdown .dropdown-menu{
	left: auto !important; right: 0 !important;
}
.selectbox-style.dropdown .dropdown-menu .dropdown-item.sub-li{
	margin-left: 10px;
}
.devider-line.dropdown-item {
    border-top: 1px solid #ccc; margin-top: 10px; padding-top: 12px !important;
}
.export-icon{
	font-size: 18px;
}
.tl-box h2 {
    font-size: 24px; color: var(--black_clr);
}
.tl-box + .invoice-date .label{
	font-weight: 400;
}
.identix-card-border {
    border: 1px solid var(--bdr_clr);
    box-shadow: none;
    border-radius: 0;
    padding: 0 !important;
}
.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #6e6b7b;
    --bs-table-striped-bg: #fafafc;
    --bs-table-active-color: #6e6b7b;
    --bs-table-active-bg: rgba(34, 41, 47, 0.1);
    --bs-table-hover-color: #6e6b7b;
    --bs-table-hover-bg: #f6f6f9;
    width: 100%;
    margin-bottom: 1rem;
    color: #6e6b7b;
    vertical-align: middle;
    border-color: #d4d4d4;
}
thead, tbody, tfoot, tr, td, th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
.table>tbody {
    vertical-align: inherit;
}
.table thead tr th,
.table tbody tr td,
.table tfoot tr th,
.form-check-label.form-label{
    font-size: 14px; line-height: 20px; font-weight: 400; color: var(--black_clr); letter-spacing: 0.14px; fill: var(--black_clr); vertical-align: top; font-family: var(--primary_font_family);
}
.table>:not(caption)>*>*{
    padding: 0.72rem 2rem;
    background-color: transparent;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px transparent;
}
.table>:not(caption)>*>*:not(:first-child){
	border-left-width: 1px;
}
.table.table-sm th,
.table.table-sm td {
    padding: 10px 10px;
}
.table.table-sm tr:nth-child(even) td{
	background: var(--gray_bg);
}
.table>:not(:last-child)>:last-child>*{
    border-bottom-color: #ebe9f1;
}
.card .table {
    margin-bottom: 0;
    border-bottom-left-radius: 0.357rem;
    border-bottom-right-radius: 0.357rem;
}
.table>thead {
    vertical-align: bottom;
}
.table.munim-table-heading:not(.table-dark):not(.table-light) thead:not(.table-dark) th{
    background-color: #c7e0ff; font-size: 12px; line-height: 18px; font-weight: 700; color: var(--black_clr); letter-spacing: 0.2px;
}
.card .table td b{
	font-weight: 600;
}
.identix-card-border table{
	white-space: nowrap;
}
.card-reports-table .table.table-sm thead tr th{
	padding: 12px 5px; background: #fff !important; position: relative;
}
.card-reports-table table thead tr th span{
	display: inline-block; vertical-align: middle; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.card-reports-table table thead tr th .la-filter{
	 color: #cecdc9;
}
/*.card-reports-table table thead tr th .la-filter:after{
	display: block; content: ""; border-right: 1px solid #cecdc9; height: 22px;
	position: absolute; right: 0; top: 0; bottom: 0; margin: auto;
}*/
.card-reports-table .table.table-sm tr td{
	padding: 10px 5px;
}
.card-reports-table .table.table-sm tr.credit_list td{
	background: #f1fff0 !important; border-top: 2px solid #99fd9d;
}
.card-reports-table .table.table-sm tr.debit_list td{
	background: #f6dad6 !important; border-top: 2px solid #f38473;
}
.small_bdr_box{
	width: 100%; height: 3px; background: var(--primary_clr); margin: 0 0 3px;
}
.credit-text {
    color: #074b9c;
}
.debit-text{
	color: #ea5455;
}
/*Top Line color*/
.payin-report-block .small_bdr_box{
	background: var(--site_clr_1) !important;
}
.payout-report-block .small_bdr_box{
	background: var(--site_clr_2) !important;
}
.bank-report-block .small_bdr_box{
	background: var(--site_clr_3) !important;
}
.loan-report-block .small_bdr_box{
	background: var(--site_clr_4) !important;
}
/*td bg color*/
/*.payin-report-block .card-reports-table .table.table-sm tr td{
	background: rgba(0,207,232,0.1);
}
.payout-report-block .card-reports-table .table.table-sm tr td{
	background: rgba(234,84,85,0.1);
}
.bank-report-block .card-reports-table .table.table-sm tr td{
	background: rgba(23,115,234,0.1);
}
.loan-report-block .card-reports-table .table.table-sm tr td{
	background: rgba(40,199,111,0.1);
}
.payin-report-block .table.table-sm th{
	background: var(--site_clr_1) !important; color: var(--white_clr) !important;
}
.payout-report-block .table.table-sm th{
	background: var(--site_clr_2) !important; color: var(--white_clr) !important;
}
.bank-report-block .table.table-sm th{
	background: var(--site_clr_3) !important; color: var(--white_clr) !important;
}
.loan-report-block .table.table-sm th{
	background: var(--site_clr_4) !important; color: var(--white_clr) !important;
}
*/

/*Payment Page*/
.payment-page-main{
	padding: 40px 20px; min-height: 100vh; background: var(--gray_bg);
}
.payment-block{
	padding: 0 .5rem .5rem; max-width: 650px; background: #f6f9fc; position: relative; min-height: 380px;
	-webkit-border-bottom-right-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
	-moz-border-radius-bottomright: 10px;
	-moz-border-radius-bottomleft: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}
.payment-top-box{
	padding: 10px 10px; background: #5d87ff; margin: 0 -0.5rem;
}
.amount-tl{
	font-size: 14px; line-height: 18px; color: var(--white_clr); font-weight: 400;
}
.amount-tl strong {
    white-space: nowrap;
}
.amount-tl span {
    word-break: break-all;
}
.timer-textbox{
    padding: 15px 0; font-size: 20px; line-height: normal; color: var(--text_clr);
}
.timer-textbox strong{
	font-weight: 500;
}
.payment-tl {
    font-size: 18px; margin: 0 0 20px;
}
.payment-form .form-check-label {
    position: relative; margin: 0 0 16px; width: 100%; cursor: pointer;
}
.payment-form .form-check-input{
	position: absolute; left: 0; top: 0; opacity: 0;
}
.pay-select{
	padding: 16px 20px; display: inline-flex; align-items: center; width: 100%; background: var(--white_clr); border: 1px solid #5d87ff; -webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;
	font-size: 14px; line-height: 20px; color: #5d87ff; font-weight: 500;
}
.pay-select .check_icon{
	display: inline-flex; align-items: center; font-size: 28px; color: var(--white_clr); position: absolute; right: 20px; top: 0; bottom: 0; opacity: 0;
}
.payment-form .form-check-label input:checked ~ .pay-select{
	background: #5d87ff; border-color: #5d87ff; color: var(--white_clr);
}
.payment-form .form-check-label input:checked ~ .pay-select .check_icon{
	opacity: 1;
}
.payment-form .form-btnbox .btn{
	padding: 8px 20px;
}
.qr_code_box{
	align-items: center; justify-content: center; margin: 20px 0;
}
.qr_code_box img{
	max-width: 220px;
}
.normal-textbox p,
p.bottom-text-style{
    font-size: 12.25px; line-height: 18.375px; color: rgba(250, 137, 107, 1);
}
p.bottom-text-style .text{
	color: rgba(93, 135, 255, 1); letter-spacing: 0;
}
.normal-textbox hr,
.bottom-text-style hr{
	border-color: #ebf1f6; margin: 0.25rem 0;
}
.small-form-block{
	width: 100%; max-width: 280px;
}
.small-form-block .form-control.form-control-sm{
	background: #eceff6;
}
.icon_copy {
    display: inline-flex; align-items: center; justify-content: center; font-size: 18px; line-height: 24px; color: #000; position: absolute; top: 0; left: 100%; width: 20px; height: 38px; cursor: pointer;
}
.icon_copy:hover{
	color: var(--primary_clr);
}
.icon_copy.active{
	color: green;
}
.copied-msg{
	font-size: 11px; line-height: normal; color: green; font-weight: 600; font-family: var(--primary_font_family); position: absolute; left: 100%;
}
.processing-screen{
	position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: #e5eff1; overflow: hidden;
}
.processing-imgbox{
	justify-content: center; margin: 0 0 10px;
}
.processing-imgbox{
	position: relative; width: 300px; height: 210px; overflow: hidden;
}
.processing-imgbox img{
	display: block; height: auto; object-fit: cover; object-position: center;
}
.processing-screen p{
	font-size: 18px; color: #3b22e0!important;
}
.pay_method_block .form-btnbox .btn-primary{
	background: #539bff; border-color: #539bff; color: var(--white_clr) !important;
}
.pay_method_block .form-btnbox .btn-primary:hover,
.pay_method_block .form-btnbox .btn-primary:focus{
	background: #4784d9; border-color: #4784d9; color: var(--white_clr) !important;
}
/*
.timeout_closed ~ .timeout_hide{
	display: none;
}
.timeout_closed ~ .back-position-out{
	position: absolute; left: 0; right: 0; bottom: 40px;
}
*/
/*Payment Page*/
.payment-logo img{
	display: block; max-width: 254px; margin: 0 auto;
}
.inner-pay-midd{
	max-width: 450px;
}
.payment-block-new{
	padding: 20px; background: var(--white_clr); position: relative; min-height: 280px; -webkit-border-radius: 10px;-moz-border-radius: 10px;border-radius: 10px;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.payable_price{
	padding: 15px 30px; display: inline-block; background: #e0f9fc; border: 1px solid #a6eef7; -webkit-border-radius: 15px;-moz-border-radius: 15px;border-radius: 15px;
	font-size: 34px; line-height: normal; color: var(--black_clr); font-weight: 600;
}
.payable_price strong{
	font-weight: 600;
}
.pay_date{
	font-size: 16px; line-height: normal; color: var(--black_clr); font-weight: 600;
}
.pay_discription {
    padding: 10px; background: #e5f8ee; border-radius: 10px; margin-top: 20px;
}
.pay_discription p {
    font-size: 15px; line-height: 24px; color: var(--site_clr_4); margin: 0;
}
.order_item {
    padding: 10px 0; text-align: left; font-size: 14px; line-height: 1.2; color: var(--black_clr);
}
.order_item + .order_item {
    border-top: 1px solid #ddd;
}
.order_item .order-label{
    margin: 0; min-width: 130px; position: relative; white-space: nowrap; font-weight: 600;
}
.order_item .order-label:after{
	display: inline-flex; content: ":"; position: absolute; left: 100%;
}
.order-value{
	padding-left: 10px;
}
.order_detail_list {
    margin: 0 auto; width: 100%;
}
.alert_icon img{
	max-width: 80px;
}
.amt_input .form-control.form-control-sm {
    padding-left: 40px; font-weight: 600;
}
.amt_text {
    display: inline-flex; align-items: center; justify-content: center; min-width: 30px; background: #eee; position: absolute; left: 1px; top: 1px; bottom: 1px; border-right: 1px solid var(--light_bdr_clr);
    -webkit-border-top-left-radius: 0.357rem; -webkit-border-bottom-left-radius: 0.357rem; -moz-border-radius-topleft: 0.357rem; -moz-border-radius-bottomleft: 0.357rem; border-top-left-radius: 0.357rem; border-bottom-left-radius: 0.357rem;
}
.overlay_link{
    display: none; position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 2;
}
.form-check-label input:checked ~ .overlay_link{
	display: block;
}
.share-option {
    justify-content: center;
}
.share-btn {
    padding: 4px 12px; display: inline-flex; align-items: center; justify-content: center; text-align: center; font-size: 12px; line-height: 18px; color: var(--black_clr); font-weight: 500; background: var(--white_clr); border: 1px solid #555; border-radius: 30px; margin: 0 5px; -moz-transition:all 0.4s ease 0s;-webkit-transition:all 0.4s ease 0s;transition:all 0.4s ease 0s;
}
.share-btn i{
	margin-right: 3px;
}
.share-btn:hover,
.share-btn:focus{
	background: var(--light_bdr_clr); border-color: var(--bdr_clr);
}
.pay_discription.text-danger{
    background: #f1f1f1;
}

/*Forms Style*/
.withdraw-form-block{
	max-width: 740px;
}
.form-inner{
	margin: 0 -10px; flex-wrap: wrap;
}
.form-inner .input-field{
	padding: 0 10px; min-width: 33.33%; max-width: 33.33%;
}
.form-inner .input-field.two-coll{
	min-width: 50%; max-width: 50%;
}
.form-inner .input-field.full-width{
	min-width: 100%; max-width: 100%;
}
.input-field .custom-file{
	display: block; height: auto; position: relative; overflow: hidden;
}
.custom-file .visibility-hidden{
	position: absolute; left: 0; top: 0;
}
.custom-file .custom-file-label{
	padding: 0 90px 0 10px; display: inline-flex; align-items: center; width: 100%; height: 38px; margin: 0; background: var(--white_clr); border: 1px solid var(--light_bdr_clr); border-radius: 0.357rem;
}
.custom-file .custom-file-label:after{
	padding: 8px 0.75rem; background: #eee !important; border: 0 !important; content: "Upload"; color: var(--black_clr) !important; font-weight: 600;
}
.mw-120{
	min-width: 120px;
}
.btn_clr_1{
	background: var(--site_clr_1); color: var(--white_clr) !important;
}
.btn_clr_2{
	background: var(--site_clr_2); color: var(--white_clr) !important;
}
.btn_clr_3{
	background: rgba(115,103,239,1); color: var(--white_clr) !important;
}
.btn_clr_4{
	background: var(--site_clr_4); color: var(--white_clr) !important;
}
.text-clr_1{
	color: var(--site_clr_1) !important;
}
.text-clr_2{
	color: var(--site_clr_2) !important;
}
.text-clr_3{
	color: rgba(115,103,239,1) !important;
}
.text-clr_4{
	color: var(--site_clr_4) !important;
}
.text-clr_5{
	color: var(--site_clr_3) !important;
}
.text-clr_6{
	color: #ff9f43 !important;
}
.text-18{
    font-size: 16px;
}
.require{
	font-style: normal; color: red;
}
.form-control.form-control-sm.focus{
    background: #eceff6;
}
/*pagination*/
.pagination {
    padding: 0; margin: 0; justify-content: center; text-align: center;
}
.pagination li{
	margin: 0; padding: 0; list-style: none;
}
.pagination li a{
    padding: 0; margin: 0; display: inline-flex; align-items: center; justify-content: center; text-align: center; min-width: 24px; height: 24px; font-size: 13px; color: #555; cursor: pointer; text-decoration: none;
}
.pagination li a:hover{
	color: var(--primary_clr); text-decoration: none;
}
.pagination li.current a{
	color: var(--black_clr);
}
.pagination li.previous a,
.pagination li.next a{
	font-size: 0; line-height: normal;
}
.pagination li.previous a:before,
.pagination li.next a:before{
	display: block; content: ""; font-size: 13px; line-height: normal; font-family: 'Line Awesome Free'; font-weight: 900;
}
.pagination li.previous a:before{
	content: "\f100";
}
.pagination li.next a:before{
	content: "\f101";
}
.pagination li.disabled a{
	opacity: 0.7; cursor: not-allowed;
}
.pagination-style {
    padding: 10px 0; justify-content: center;
}
.pagination-style p,
.dt-info{
    margin: 0; font-size: 12px; line-height: normal; color: #555;
}
.page-input,
.page-select{
	margin-left: 10px;
}
.page-input .form-control.form-control-sm{
    padding: 0 5px; width: 32px; height: 28px; text-align: center;
}
.page-select .small-select-style .form-control.form-control-sm{
	min-width: 60px; height: 28px;
}
.page-select .small-select-style:after{
	border-left: 1px solid #d8d6de6e;
}
.pagination-input,
.dt-length label{
	font-size: 12px; line-height: normal; color: #555; margin: 0;
}
.dt-paging .dt-paging-button{
    border: 0; background: none; font-size: 13px; line-height: 16px; color: #555;
}
.dt-paging .dt-paging-button{
	color: var(--black_clr);
}
.dt-paging .dt-paging-button:hover{
    color: var(--primary_clr);
    text-decoration: none;
}
.dt-paging .dt-paging-button.previous,
.dt-paging .dt-paging-button.next,
.dt-paging .dt-paging-button.first,
.dt-paging .dt-paging-button.last{
	font-size: 0; line-height: normal;
}
.dt-paging .dt-paging-button.previous:before,
.dt-paging .dt-paging-button.next:before,
.dt-paging .dt-paging-button.first:before,
.dt-paging .dt-paging-button.last:before{
	display: block; content: ""; font-size: 13px; line-height: normal; font-family: 'Line Awesome Free'; font-weight: 900;
}
.dt-paging .dt-paging-button.previous:before{
	content: "\f104";
}
.dt-paging .dt-paging-button.next:before{
	content: "\f105";
}
.dt-paging .dt-paging-button.first:before{
	content: "\f100";
}
.dt-paging .dt-paging-button.last:before{
	content: "\f101";
}
.dt-paging .dt-paging-button.disabled{
	opacity: 0.7; cursor: not-allowed;
}

#pageNumberInput,
.dt-length .dt-input{
    padding: 0 5px; width: 32px; height: 28px; text-align: center; background: var(--white_clr); border: 1px solid var(--light_bdr_clr); font-size: 14px; color: var(--black_clr); border-radius: 0.357rem; outline: 0 !important;
}
.page-select .small-select-style .form-control.form-control-sm{
	min-width: 60px; height: 28px;
}
.page-select .small-select-style:after{
	border-left: 1px solid #d8d6de6e;
}
.dt-length {
    margin-left: 5px;
}
.dt-length .dt-input {
    width: auto;
    text-align: left;
    margin-right: 5px;
}


/*commission Form*/
.commiss-form{
	position: relative; flex-wrap: nowrap;
}
.commiss-form .input-field{
	min-width: inherit; max-width: inherit;
}
.commiss-form .form-btnbox{
	padding: 24px 0 0;
}
.set-text{
	white-space: nowrap; font-size: 13px;
}

/*WithdrawalForm*/
.search-status-block{
	padding-left: 10px; padding-right: 10px; flex-wrap: wrap;
}
.width-option{
	width: 100%; justify-content: center;
}
.radio-option-label{
	margin: 0 20px;
}
.radio-option-label span{
	margin-left: 10px;
}
.radio-option-label label{
	display: inline-block; margin: 0 0 0 10px;
}

.search-auto.small-input-style .form-control.form-control-sm{
	min-width: 40px;
}

/*marqee-block*/
.marqee-block{
	width: 100%; padding: 0 10px;
}
.marqee-inn{
    align-items: center;
    height: 30px;
    flex: 1;
}
.marqee-inn marquee {
    width: calc(100% - 35px);
    padding: 6px 10px;
    font-size: 14px; line-height: 18px; color: var(--black_clr); font-weight: 400;
}
.marqee-inn .news-title img {
    width: 30px;
}
.btn_style2{
    color: #ffffff !important;
    background-color: #074b9c !important;
    border-color: #074b9c !important;
}
.btn_style2:hover{
    color: #ffffff !important;
    background-color: #063f84 !important;
    border-color: #063f84 !important;
}
.hospital-tiles{
    padding: 17px 15px; position: relative; background: #ffffff; color: #ffffff; text-align: center; box-shadow: 0 0 25px rgba(26, 107, 225, 0.1); -webkit-border-radius: 10px; -moz-border-radius: 10px; border-radius: 10px;
}
.hospital-tiles.secondary1{
    background: #074b9c;
}
.hospital-tiles.secondary2{
    background: #33af65;
}
.hospital-tiles.secondary3{
    background: #fed130;
}
.hospital-tiles.secondary4{
    background: #ec4842;
}
.hospital-tiles p{
	margin: 0 0 1rem 0;
}
.hospital-tiles p:last-child{
	margin-bottom: 0;
}
.payin-rep-list .card-growth{
	display: flex; align-items: center;
}
.payin-rep-list .card-growth .card-head{
	margin: 0;
}
.payin-rep-list .card-growth .price-text {
    font-size: 20px; line-height: 34px;
}
.payin-rep-list .card-growth .small-text{
	font-size: 14px;
}
.status-select{
    background: #074b9c; border-color: #074b9c; -webkit-border-radius: 4px;-moz-border-radius: 4px;border-radius: 4px;
}
.status-select .form-control.form-control-sm{
	color: #fff; min-width: 90px; -webkit-border-radius: 4px;-moz-border-radius: 4px;border-radius: 4px;
}
.status-select.small-select-style:after{
	color: #fff;
}
.status-select .form-control.form-control-sm option{
	color: #000;
}
.status_text{
	display: flex; align-items: center; color: #12a271; font-weight: 700;
}
.status_text i{
	font-size: 16px; margin-right: 5px;
}
.site-imgbox img {
    display: block; margin: 0 auto; max-width: 180px;
}
.hit-report-block .card-reports-table .table.table-sm tr td{
	vertical-align: middle;
}
.hit-report-block .hit-btnbox{
    margin: -2px 0;
}
.approve-btn-effect{
	position: relative; margin: 0 0 0 5px;
}
/*Switch Button Start*/
.switch-btnbox {
    display: inline-flex; align-items: center; justify-content: center; text-align: center; position: relative; min-width: 80px; height: 28px;
    background: #074b9c; overflow: hidden; border-radius: 0.1875rem;
}
.switch-btnbox input{
    position: absolute; padding: 0; left: 0; top: 0; opacity: 0; margin: 0;
}
.switch-text{
	padding: 0 0 2px; display: inline-flex; align-items: center; justify-content: center; width: 100%; height: 100%; position: absolute; left: 0; top: 0; font-style: normal;
}
.switch-text.off {
    opacity: 0;
}
.switch-btnbox input:checked ~ .switch-text.off{
    opacity: 1;
}
.switch-btnbox input:checked ~ .switch-text.on{
    opacity: 0;
}
.switch-btnbox .switch-text{
	background: none !important; border: 0;
}
.btn-bg{
	display: block; background: #12a271; position: absolute; left: 0; top: 0; width: 0; height: 100%; -moz-transition:all 0.4s ease 0s;-webkit-transition:all 0.4s ease 0s;transition:all 0.4s ease 0s; border-radius: 0.1875rem;
}
.switch-btnbox input:checked ~ .btn-bg{
	width: 100%;
}
/*Switch Button End*/

/*footer fix whatsapp icon*/
.bottom-fix-whatsapp{
	position:fixed; bottom:12px; right:10px; width:0; height:auto; z-index:99999 !important; display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;
}
.bottom-fix-whatsapp a i{
	display: inline-flex; align-items: center; justify-content: center; font-size:34px; width:52px; height:52px; color:#ffffff;background:#25d366;font-weight:400; -webkit-box-shadow:1px 6px 24px 0 rgba(7,94,84,.24);box-shadow:1px 6px 24px 0 rgba(7,94,84,.24);border-radius:50%;-webkit-transition:0.7s all;-o-transition:0.7s all;transition:0.7s all;
}
.munim-table-heading .small-select-style.form-group {
    margin-bottom: 0;
}
.copy-text-box {
    position: relative;
}
.copy-text-box .copied-msg {
    left: 20px;
    top: 5px;
}
.qr-link-items {
    padding: 15px 20px;
    background: #f8f8f8;
}
.genr-lable {
    font-size: 18px; line-height: normal; font-weight: 700; min-width: 200px;
}
.genrated-link {
    padding: 15px 20px;
    position: relative;
}





/*===============:: MEDIA CSS ::==============*/
/* 1366px View */
@media screen and (max-width:1440px){
.price-text{
	font-size: 18px;
}
.card-inflow .iconbox {
    min-width: 60px; max-width: 60px; height: 60px; margin-right: 15px;
}
.card-inflow .iconbox img {
    max-width: 40px; height: 30px;
}
.gst-login-img img {
    width: 100%;
}
}
/* iPad 1024px View */
@media screen and (max-width:1199px){
.card-list .items{
	padding: 7px; margin: 0;
}
.withdraw-form-block{
	max-width: 100%;
}
}
/* iPad 768px view*/
@media screen and (max-width:991px){
.page-body-wrapper {
    margin-left: 0;
}
.navbar{
	z-index: 1041;
}
.navbar .navbar-brand-wrapper {
    width: 174px; border-bottom: 1px solid var(--bdr_clr); padding-left: 50px;
}
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo{
	display: block; padding: 0;
}
.navbar .navbar-brand-wrapper .navbar-brand img{
	width: 100%; height: auto;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link{
	margin: 0 3px;
}
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right .nav-item:last-child .nav-link{
	margin-right: 0;
}
.navbar-profile .mdi-menu-down{
	display: none !important;
}
.mobi-menu-icon {
    display: inline-flex; align-items: center; justify-content: center; position: absolute; left: 0; top: 0; bottom: 0; height: 62px;
}
.mobi-menu-icon .navbar-toggler svg {
	display: block; height: 22px; width: 22px; opacity: 0.7;
}
.sidebar .nav {
    padding-top: 6px;
}
.help-nav-btnbox .help-button {
    padding: 0; min-width: 32px; max-width: 32px; height: 35px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown.help-nav-btnbox .dropdown-toggle:after{
	display: none;
}
.help-nav-btnbox .help-button i{
	margin: 0 auto;
}
.noti-iconbox{
	display: inline-flex; align-items: center; justify-content: center; font-size: 20px; line-height: normal; color: var(--text_clr); padding: 0; min-width: 32px; max-width: 32px; height: 35px; border: 1px solid var(--bdr_clr); -webkit-border-radius: 4px;-moz-border-radius: 4px;border-radius: 4px;
}
.navbar .navbar-menu-wrapper .search input{
	height: 35px;
}
.search-btn{
	width: 31px; height: 31px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link.search{
    padding: 0; max-width: 240px; margin: 0 3px 0 auto !important;
}
.live_chat_block{
	display: none;
}
.gst-responsive-width {
    max-width: 480px;
}
.account-login-main .bg-img{
	display: none;
}
.gst-login-logo img{
	max-width: 160px;
}
.marqee-block {
    max-width: 380px;
    margin-left: auto;
}
.scrollbar-container {
    padding: 0 0 20px;
}

}
/* iPhone-6 plus 736 (Landscape) view */
@media screen and (max-width:767px){
.mobi-menu-icon{
	left: 15px;
}
.mobi-menu-icon .navbar-toggler{
    display: inline-flex; align-items: center; justify-content: center; background: var(--primary_clr); width: 34px; height: 34px; border-radius: 5px; padding: 0;
}
.mobi-menu-icon .navbar-toggler svg {
	color: var(--white_clr); opacity: 1;
}
.navbar .navbar-brand-wrapper {
    width: 184px; padding-left: 60px;
}
.content-wrapper {
    padding: 1rem 5px;
}
.scrollbar-container {
    padding: 0 0 100px; height: 100vh;
}
.navbar.fixed-top{
	height: 62px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link.search{
	position: fixed; left: 0; right: 0; top: 62px; max-width: 100%; margin: 0 !important; background: var(--white_clr); box-shadow: 0 3px 5px rgba(0,0,0,0.1);
}
.navbar .navbar-menu-wrapper .search input{
	-webkit-border-radius: 0;-moz-border-radius: 0;border-radius: 0;
}
.mobi-search-toggle{
	display: none !important;
}
.mobi-search-toggle.active{
	display: block !important;
}
.search-mobi{
    display: block; margin-right: 3px;
}
.noti-iconbox.active .la-search:before{
    content: "\f00d";
}
.help-button + .dropdown-menu.navbar-dropdown {
    top: -4px !important;
}
.notifications-list {
    width: 100%; max-width: 80%; top: -9px !important;
}
.identix-toggle-dashboard {
    min-width: 100%;
    margin-top: 10px;
}
.dashboard-top-compare-sec {
    padding: 0 10px;
}
.card-growth {
    padding: 15px;
    height: auto;
}
.card-growth .card-head {
    margin-bottom: 0;
}
.card-growth .card-text-link {
    min-width: 100%; justify-content: flex-end; margin-top: -32px;
}
.card-head-title {
    margin: 0 0 10px;
}
.card .card-body {
    padding: 15px;
}
.card-revenue .revenue-tl {
    margin-bottom: 0; position: relative; padding-left: 70px;
}
.card-revenue .total-textbox {
    padding-left: 70px;
}
.card-revenue .revenue-tl .icon{
	position: absolute; left: 0; top: 0;
}
.card-flt-box .label {
    display: none;
}
.gst-card {
    padding: 0 !important; box-shadow: 0 0 0 rgba(23,115,234,0); -webkit-border-radius: 0;-moz-border-radius: 0;border-radius: 0;
}
.gst-login-logo {
    padding: 10px 0 30px !important;
}
.munim-switch-btn .nav{
	flex-wrap: nowrap;
}
.tl-box h2 {
    font-size: 15px;
}
.report-title-head{
	flex-wrap: nowrap !important;
}
.report-title-head .identix-toggle-dashboard{
	min-width: inherit; margin: 0 0 0 auto; flex-wrap: wrap;
}
.report-title-head .invoice-list-d-flex{
	flex-wrap: wrap;
}
.report-title-head .invoice-date {
    min-width: 100%; margin: 10px 0 0;
}
.payment-page-main{
	padding: 10px 15px;
}
.payment-top-box {
    flex-direction: column;
    justify-content: center;
}
.amount-tl {
    min-width: 100%;
    margin: 3px 0;
}
.timer-textbox,
.payment-tl {
    font-size: 16px;
}
.processing-imgbox {
    height: 120px;
}
.processing-imgbox img {
    min-width: 600px;
}
.processing-screen p {
    font-size: 16px;
}
.gst-welcome-text h2 {
    font-size: 20px;
}
.copied-msg {
    left: auto; right: -10px; top: 100%;
}
.withdraw-form-block {
    max-width: 100%;
    padding: 0 !important;
}
.form-inner .input-field,
.form-inner .input-field.two-coll{
	min-width: 100%; max-width: 100%;
}
.small-form-block{
	max-width: 100%;
}
.report-title-head .invoice-list-d-flex.bank-tl{
	flex-wrap: nowrap; white-space: nowrap;
}
.commiss-form {
    flex-wrap: wrap;
}
.commiss-form .input-field {
    min-width: 50%; max-width: 50%;
}
.commiss-form .form-btnbox{
	padding: 24px 10px 0; position: relative; right: 0; top: 0;
}
.commiss-form .form-btnbox .btn{
	min-width: 100px;
}
.small-select-style .form-control.form-control-sm,
.small-input-style .form-control.form-control-sm{
	min-width: 60px;
}
.flt-top-payout .commiss-form .form-btnbox{
	padding-top: 0;
}
.user-form-block.p-4 {
    padding: 5px !important;
}
.sidebar .nav.sub-menu .nav-item .nav-link{
	font-size: 13px;
}
.card-body .small-form-block.p-4 {
    padding: 5px !important;
}
.marqee-block{
	display: none !important;
}
.navbar .navbar-menu-wrapper .navbar-nav{
	margin-left: auto;
}
.total-reports-lits .items{
	min-width: 100%;
}
.total-reports-lits .items + .items{
	margin-top: 10px;
}
.flt-top-bank {
    flex-wrap: wrap;
}
.flt-top-bank .commiss-form + .identix-toggle-dashboard{
	margin: -38px 0 15px;
}
.flt-top-bank .identix-toggle-dashboard .flt-selectbox{
	margin-left: auto;
}
.pagination-style{
	flex-wrap: wrap; text-align: center;
}
.pagination-style .pagination{
	min-width: 100%; margin: 5px 0;
}
.navbar .navbar-menu-wrapper{
	padding-left: 0;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link .navbar-profile .navbar-profile-name{
	display: block !important;
}

}
/*767 End*/
@media screen and (max-width:374px){
	.navbar-profile-name .name-text{max-width: 70px;}
}